const _ = {
  compact: require('lodash/compact'),
  get: require('lodash/get'),
  uniq: require('lodash/uniq'),
};
import * as React from 'react';
import qs from 'qs';
import { MutationFn } from 'react-apollo';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import mockupRoutes from 'inkp-mockup-sdk/routes';

import { Subscribe } from 'infra-frontend/helpers/apollo';
import { routes, url } from 'inkp-routes/public';

import config from '../../config';
import { QUERY_PRODUCTS_BY_ID } from '../../graphql/products';
import DesignTool, { TypedSubscribe } from '../DesignTool/index2';
import DesignCart, { DesignCartProductInput } from '../../states/global/designCart';
import { QueryProducts } from '../DesignTool/queries';
import DesignToolStateContainer from '../DesignTool/DesignToolStateContainer';
import {
  MUTATION_CREATE_SHARABLE,
  MUTATION_UPDATE_SHARABLE,
  MutationCreateSharable,
  MutationUpdateSharable,
} from '../../graphql/shareable';
import { MutationUpdateUser, MUTATION_UPDATE_USER } from '../../graphql/users';
import {
  MutationcreateShareableArgs,
  MutationupdateShareableArgs,
  ShareableProducItemInput,
  ShareableDesignInput,
  Shareable,
  Mutation as MutationDesignResults,
} from 'inkp-design-sdk/types.g';
import { normalizeDesigns } from '../../util/shareable';
import { findServiceError } from '../../util/apollo';
import { User, MutationupdateUserArgs, Mutation as UserMutationResults } from 'inkp-user-sdk/types.g';
import { isUserRegistered } from 'inkp-user-sdk/user';

import Alert from 'inkp-components/dist/Components/Alert';
import Modal from 'inkp-components/dist/Components/Modal';
import { Product } from 'inkp-product-sdk/types.g';

const DEFAULT_MAX_ATTEMPTS = 2;

interface DuplicateDesignData {
  id: string;
  name: string;
  user: string;
}

function AddDefaultProduct(props: {
  designToolStateContainer: DesignToolStateContainer;
  selected: DesignCartProductInput;
  products: DesignCartProductInput[];
  color: string;
  designCart: DesignCart;
  history: any;
  match: any;
  location: any;
  onNext: () => void;
}) {
  const { designCart, products, selected, color } = props;
  const productColor = selected.colors.find((_color) => _color.name === color) || selected.colors[0];
  React.useEffect(() => {
    props.designCart.addProduct(selected, productColor.name, products);
  }, []);

  return <DesignTool designCart={designCart} designCartState={designCart.state} {...props} />;
}

function shouldloadDefaultProduct(designCart: DesignCart, queryProductId?: string): boolean {
  return !queryProductId && designCart.state.products.length === 0;
}

function shouldloadNewProduct(queryProductId?: string): boolean {
  return !!queryProductId;
}

function onNext({
  designCart,
  designToolStateContainer,
  createSharable,
  updateShareable,
  shareable,
  setShowWarningModal,
  setShowErrorModal,
  setDuplicatedDesignData,
  attemptsCounter,
}: {
  designCart: DesignCart;
  designToolStateContainer: DesignToolStateContainer;
  createSharable: MutationFn<MutationDesignResults, MutationcreateShareableArgs>;
  updateShareable: MutationFn<MutationDesignResults, MutationupdateShareableArgs>;
  shareable?: Shareable;
  setShowWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDuplicatedDesignData: React.Dispatch<React.SetStateAction<DuplicateDesignData>>;
  attemptsCounter: number;
}) {
  return () => {
    const { save } = designToolStateContainer.state;
    const { state } = designCart;
    const designId = state.products[0].designId;
    if (state.saved && designId) {
      designToolStateContainer.addToCart(designId, state.products[0].productId, state.products[0].colors[0]);
      designToolStateContainer.setShowCart(true, false);
      return;
    }

    designToolStateContainer.setShowCart(true, true);
    if (!shareable) {
      createSharable({
        variables: {
          shareable: {
            name: save.data.designName,
          },
          products: getProducts(designCart),
          designs: getDesigns(designCart),
        },
      }).catch(
        handleError({
          updateShareable,
          designCart,
          designToolStateContainer,
          setShowWarningModal,
          setShowErrorModal,
          setDuplicatedDesignData,
          attemptsCounter,
        })
      );
    } else {
      updateShareable({
        variables: {
          shareableId: shareable.id,
          shareable: {
            name: save.data.designName,
          },
          products: getProducts(designCart),
          designs: getDesigns(designCart),
        },
      }).catch(
        handleError({
          updateShareable,
          designCart,
          designToolStateContainer,
          setShowWarningModal,
          setShowErrorModal,
          setDuplicatedDesignData,
          attemptsCounter,
        })
      );
    }
  };
}

function handleError({
  updateShareable,
  designCart,
  designToolStateContainer,
  setShowWarningModal,
  setShowErrorModal,
  setDuplicatedDesignData,
  attemptsCounter,
}: {
  updateShareable: MutationFn<MutationDesignResults, MutationupdateShareableArgs>;
  designCart: DesignCart;
  designToolStateContainer: DesignToolStateContainer;
  setShowWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDuplicatedDesignData: React.Dispatch<React.SetStateAction<DuplicateDesignData>>;
  attemptsCounter: number;
}) {
  return (error: any): any => {
    const serviceError = findServiceError(error);
    if (serviceError && serviceError.extensions) {
      if (serviceError.extensions.code === 'DUPLICATE_DESIGN_NAME') {
        const duplicateData: { id: string; name: string; user: string } = serviceError.data.data;
        setDuplicatedDesignData(duplicateData);
        return updateShareable({
          variables: {
            shareableId: duplicateData.id,
            shareable: {
              name: duplicateData.name,
              email: duplicateData.user,
            },
            products: getProducts(designCart),
            designs: getDesigns(designCart),
          },
        }).catch(
          handleError({
            updateShareable,
            designCart,
            designToolStateContainer,
            setShowWarningModal,
            setShowErrorModal,
            setDuplicatedDesignData,
            attemptsCounter,
          })
        );
      } else {
        designToolStateContainer.setShowCart(true, false);
        setShowWarningModal(attemptsCounter < DEFAULT_MAX_ATTEMPTS);
        return setShowErrorModal(attemptsCounter >= DEFAULT_MAX_ATTEMPTS);
      }
    } else {
      setShowWarningModal(attemptsCounter < DEFAULT_MAX_ATTEMPTS);
      return setShowErrorModal(attemptsCounter >= DEFAULT_MAX_ATTEMPTS);
    }
  };
}

function getProducts(designCart: DesignCart) {
  const { products } = designCart.state;
  const normalizedProducts: ShareableProducItemInput[] = [
    {
      productId: products[0].productId,
      colors: [
        {
          name: products[0].colors[0],
          selected: true,
        },
      ],
      designIndex: 0,
      selected: true,
    },
  ];
  return normalizedProducts;
}

function getDesigns(designCart: DesignCart) {
  const { designs } = designCart.state;
  const normalizedDesigns: ShareableDesignInput[] = normalizeDesigns(designs);
  return normalizedDesigns;
}

function onSaved({
  designToolStateContainer,
  designCart,
  currentUser,
  updateUser,
}: {
  designToolStateContainer: DesignToolStateContainer;
  designCart: DesignCart;
  currentUser: User;
  updateUser: MutationFn<UserMutationResults, MutationupdateUserArgs>;
}) {
  return (result: MutationDesignResults) => {
    const { updateShareable, createShareable } = result;
    const shareable = updateShareable || createShareable;
    if (shareable) {
      const { design, productId, colors } = shareable.products[0];
      const product = shareable.products[0].product ? (shareable.products[0].product as Product) : null;
      const side = design!.sides.filter((side) => {
        return side.shapes.length > 0;
      })[0].name;
      const colorObject =
        product &&
        product.colors.find((productColor) => {
          return productColor.name === designCart.state.products[0].colors[0];
        });
      const template =
        product &&
        product.designTemplates.find((designTemplate) => {
          return designTemplate.side === side;
        });
      if (template && colorObject) {
        console.log('prerendering image');
        const preloadMockupUrl = url('mockup', mockupRoutes.mockup, {
          designId: design!.id,
          templateIdentifier: template.id,
          color: colorObject.hex,
          side: template.side,
          size: 'medium',
        });
        const preloadedImg = new Image();
        preloadedImg.src = preloadMockupUrl;
      }
      designToolStateContainer.saveShareable(shareable);
      designCart.assignDesignIds(shareable);
      designToolStateContainer.addToCart(design!.id, productId, colors[0].name);

      if (!isUserRegistered(currentUser) && shareable.email) {
        updateUser({
          variables: {
            userData: {
              userId: currentUser.id,
              email: shareable.email,
            },
          },
        });
      }
    }
  };
}

interface DesignToolPageProps extends RouteComponentProps {
  currentUser: User;
}

export default (props: DesignToolPageProps) => {
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [attemptsCounter, setAttemptsCounter] = React.useState(0);
  const [duplicatedDesignData, setDuplicatedDesignData] = React.useState<DuplicateDesignData>({
    id: '',
    name: '',
    user: '',
  });

  return (
    <Subscribe to={['designCart']} namespace="DesignToolPage">
      {(designCart: DesignCart) => (
        <TypedSubscribe to={[DesignToolStateContainer]} namespace="DesignToolInteraction">
          {(designToolStateContainer: DesignToolStateContainer) => (
            <MutationUpdateUser mutation={MUTATION_UPDATE_USER}>
              {(updateUser) => (
                <MutationCreateSharable
                  mutation={MUTATION_CREATE_SHARABLE}
                  onCompleted={onSaved({
                    designToolStateContainer,
                    designCart,
                    currentUser: props.currentUser,
                    updateUser,
                  })}
                >
                  {(createSharable, { data, error: createError, loading }) => (
                    <MutationUpdateSharable
                      mutation={MUTATION_UPDATE_SHARABLE}
                      onCompleted={onSaved({
                        designToolStateContainer,
                        designCart,
                        currentUser: props.currentUser,
                        updateUser,
                      })}
                    >
                      {(updateShareable, { data, error: updateError, loading }) => {
                        const renderTryAgainModal = () => (
                          <Modal>
                            <Alert
                              alerts={[
                                {
                                  type: 'warning',
                                  title: 'Oh no! :(',
                                  content: 'Sorry something went wrong while saving your design. Please try again.',
                                  onClose: () => {
                                    return setShowWarningModal(false);
                                  },
                                  primaryAction: 'Try Again',
                                  onPrimaryAction: () => {
                                    const newAttemptsCounter = attemptsCounter + 1;
                                    if (duplicatedDesignData.id && duplicatedDesignData.name) {
                                      updateShareable({
                                        variables: {
                                          shareableId: duplicatedDesignData.id,
                                          shareable: {
                                            name: duplicatedDesignData.name,
                                          },
                                          products: getProducts(designCart),
                                          designs: getDesigns(designCart),
                                        },
                                      });
                                    } else {
                                      const { save } = designToolStateContainer.state;
                                      createSharable({
                                        variables: {
                                          shareable: {
                                            name: save.data.designName,
                                          },
                                          products: getProducts(designCart),
                                          designs: getDesigns(designCart),
                                        },
                                      }).catch(
                                        handleError({
                                          updateShareable,
                                          designCart,
                                          designToolStateContainer,
                                          setShowWarningModal,
                                          setShowErrorModal,
                                          setDuplicatedDesignData,
                                          attemptsCounter: newAttemptsCounter,
                                        })
                                      );
                                    }
                                    setShowWarningModal(false);
                                    setAttemptsCounter(newAttemptsCounter);
                                    return designToolStateContainer.setShowCart(true, true);
                                  },
                                },
                              ]}
                            ></Alert>
                          </Modal>
                        );

                        const renderCreateDesignErrorModal = () => (
                          <Modal>
                            <Alert
                              alerts={[
                                {
                                  type: 'error',
                                  title: "Oops! :'(",
                                  content:
                                    'Something went wrong. We are very sorry. Please try again later or contact us.',
                                  onClose: () => {
                                    return setShowErrorModal(false);
                                  },
                                },
                              ]}
                            ></Alert>
                          </Modal>
                        );

                        const queryParams = qs.parse(`${props.location.search}`, { ignoreQueryPrefix: true });
                        const shouldLoadDefault = shouldloadDefaultProduct(designCart, queryParams.product);
                        const shouldLoadNew = shouldloadNewProduct(queryParams.product);
                        const productId = queryParams.product || config.defaultDesignToolProduct.id;
                        const currentProductIds = designCart.state.products.map(({ productId }) => productId);
                        const color: string = shouldLoadNew
                          ? queryParams.color || ''
                          : config.defaultDesignToolProduct.color;
                        const ids: string[] = _.compact(_.uniq([...currentProductIds, productId]));
                        if (shouldLoadDefault || shouldLoadNew) {
                          return (
                            <QueryProducts query={QUERY_PRODUCTS_BY_ID} variables={{ ids }}>
                              {({ loading, error, data }) => {
                                if (loading) {
                                  return null;
                                }
                                if (error) return <Redirect to={routes.app.base} />;
                                if (!data || !data.productsById) return null;
                                const products = _.compact(data.productsById);
                                const selected = data.productsById.find(
                                  (product) => product && product.id === productId
                                ) as DesignCartProductInput;
                                if (!selected) return null;

                                return (
                                  <AddDefaultProduct
                                    designToolStateContainer={designToolStateContainer}
                                    selected={selected}
                                    products={products}
                                    color={color}
                                    designCart={designCart}
                                    onNext={onNext({
                                      designCart,
                                      designToolStateContainer,
                                      createSharable,
                                      updateShareable,
                                      setShowWarningModal,
                                      setShowErrorModal,
                                      setDuplicatedDesignData,
                                      attemptsCounter,
                                    })}
                                    {...props}
                                  />
                                );
                              }}
                            </QueryProducts>
                          );
                        }
                        return (
                          <>
                            <DesignTool
                              designToolStateContainer={designToolStateContainer}
                              designCartState={designCart.state}
                              designCart={designCart}
                              onNext={onNext({
                                designCart,
                                designToolStateContainer,
                                createSharable,
                                updateShareable,
                                setShowWarningModal,
                                setShowErrorModal,
                                setDuplicatedDesignData,
                                attemptsCounter,
                              })}
                              {...props}
                            />
                            {showWarningModal && renderTryAgainModal()}
                            {showErrorModal && renderCreateDesignErrorModal()}
                          </>
                        );
                      }}
                    </MutationUpdateSharable>
                  )}
                </MutationCreateSharable>
              )}
            </MutationUpdateUser>
          )}
        </TypedSubscribe>
      )}
    </Subscribe>
  );
};
